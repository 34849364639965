// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-article-template-js": () => import("./../../../src/components/articleTemplate.js" /* webpackChunkName: "component---src-components-article-template-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cities-js": () => import("./../../../src/pages/cities.js" /* webpackChunkName: "component---src-pages-cities-js" */),
  "component---src-pages-co-funders-js": () => import("./../../../src/pages/co-funders.js" /* webpackChunkName: "component---src-pages-co-funders-js" */),
  "component---src-pages-collaborators-js": () => import("./../../../src/pages/collaborators.js" /* webpackChunkName: "component---src-pages-collaborators-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insights-js": () => import("./../../../src/pages/insights.js" /* webpackChunkName: "component---src-pages-insights-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-podcasts-birthwork-js": () => import("./../../../src/pages/podcasts/birthwork.js" /* webpackChunkName: "component---src-pages-podcasts-birthwork-js" */),
  "component---src-pages-podcasts-maternal-health-innovation-js": () => import("./../../../src/pages/podcasts/maternal-health-innovation.js" /* webpackChunkName: "component---src-pages-podcasts-maternal-health-innovation-js" */)
}

